import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'
import format from 'date-fns/format'

const BlogIndex = ({ data }) => {
  const { edges } = data.allMdx
  const posts = edges.map(({ node }) => node)

  return (
    <Layout>
      <SEO title={'Blog'} />
      <div className="container">
        <ul className="blogArticleList">
          {posts
            .sort(
              (p1, p2) =>
                new Date(p2.frontmatter.date) - new Date(p1.frontmatter.date)
            )
            .map(post => {
              const featuredImgFluid =
                post.frontmatter.cover.childImageSharp.fluid

              return (
                <li key={post.id} className="blogArticleListItem">
                  <Link to={post.fields.slug}>
                    <Img
                      fluid={featuredImgFluid}
                      className="blogArticleListItem__Image"
                      alt={post.frontmatter.title}
                    />
                    <div className="blogArticleListItem__Date">
                      {format(new Date(post.frontmatter.date), 'MMMM Do, YYYY')}
                    </div>
                    <h2 className="blogArticleListItem__Title">
                      {post.frontmatter.title}
                    </h2>
                    <p className="blogArticleListItem__Description">
                      {post.frontmatter.shortDescription}
                    </p>
                  </Link>
                </li>
              )
            })}
        </ul>
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query blogIndex {
    allMdx {
      edges {
        node {
          id
          frontmatter {
            title
            date
            shortDescription
            cover {
              childImageSharp {
                fluid(maxWidth: 450) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
export default BlogIndex
